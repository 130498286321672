.slider {
    width: 70%;
    border-radius: 20px;
    overflow: hidden;
    margin-left: 15px;
    display: none;
}

.slides {
    width: 500%;
    height: 500px;
    display: flex; 
}

.slides input {
    display: none;
}

.slide {
    width: 20%;
 /*   transition: 2s;*/
}

/*Manual*/

.navigation-manual {
    position: absolute;
    width: 60%;
    margin-top: -40px;
    display: flex;
    justify-content: center;
}

.manual-btn {
    border: 2px solid brown;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
    transition: 1s;
}

.manual-btn:not(:last-child) {
    margin-right: 40px;
}
.manual-btn:hover {
    background: yellow;
}

#radio1:checked ~ .first {
    margin-left: 0;
}
#radio2:checked ~ .first {
    margin-left: -20%;
}
#radio3:checked ~ .first {
    margin-left: -40%;
}
#radio4:checked ~ .first {
    margin-left: -60%;
}

/* AUto*/

.navigation-auto {
    position: absolute;
    display: flex;
    width: 60%;
    justify-content: center;
    margin-top: 460px;
}

.navigation-auto div {
    border: 2px solid red;
    padding: 5px;
    border-radius: 10px;
    transition: 1s;
}

.navigation-auto div:not(:last-child) {
    margin-right: 40px;
}

#radio1:checked ~ .navigation-auto .auto-btn1 {
    background: yellowgreen;
}

#radio2:checked ~ .navigation-auto .auto-btn2 {
    background: yellowgreen;
}
#radio3:checked ~ .navigation-auto .auto-btn3 {
    background: yellowgreen;
}
#radio4:checked ~ .navigation-auto .auto-btn4 {
    background: yellowgreen;
}

.primarysection {
    text-align: center;
    padding: 20px;
    background-color: rgb(241 245 249);
    border-radius: 20px;
  }

  .homeTitle {
    font-family: 'Piazzolla', serif;
    font-size: 32px;
  }

  @media screen and (min-width: 961px) {
    .mainsection {
        display: flex;
        margin-top: 20px;
        background-color: black;
      }
    .primarysection {
      width: 40%;
    }

    .desktopview {
        display: block;
    }

    .mobileview {
        display: none;
    }

    .slide img {
        width: 100%;
       height: 500px;
    }

  }

@media screen and (max-width: 960px) {

    .navigation-auto {
        display: none;
    }

    .slide img {
        width: 100%;
       height: 250px;
       border-radius: 20px;
    }

  .slider {
    width: 100%;
    margin-left: 0;
    border-radius: 0;
    display: none;
  }

  .slides {
    height: 250px;
    margin-top: 20px;
    
  }

  .primarysection {
    border-radius: 0;
  }

  .desktopview {
    display: none;
}

.mobileview {
    display: block;
}
.navigation-manual {
    width: 100%;
}
}

