body {
  margin-left: 8px;
  margin-right: 8px;
  padding: 0;
}

.red {
  color: red;
}

.iframe {
  border-radius: 20px;
    border: 0px;
}

.paras.gpic1 {
  background-image: linear-gradient(259deg,#22a6b3 0%,#7ed6df 100%)
}
.paras.gpic2 {
  background-image: linear-gradient(79deg,#ffbe76 0%,#f0932b 100%)
}
.paras.gpic3, .gpic3 {
  background-image: linear-gradient(102deg,#c8d6e5 0%,#8395a7 100%)
}
.paras.gpic4 {
  background-image: linear-gradient(250deg,#eb4d4b 0%,#f57406 100%);
}
.paras.gpic5 {
  background-image: linear-gradient(250deg,#f10074  0%, #f480b7 100%);
}
.paras.gpic6 {
  background-image: linear-gradient(250deg,#43ff00 0%,#ade9c9 100%);
}
 
 #mybutton {
  position: fixed;
  cursor: pointer;
  bottom: 0px;
  padding: 15px 25px;
  right: 8px;
  background-color: rgb(124 58 237)	;
  border-radius: .5rem;
  font-size: x-large;
  color: rgb(248 250 252);
  opacity: .7;
 }

#root {

    background-color: rgb(226 232 240 );
}

.green {
  background-color: #00ff99;
}

.colorgreen {
  color: #00ff99;
}

.mx-top20 {
  margin-top: 20px;
}

.button {
  padding: 15px 40px;
    border-radius: 8px;
    border: 0px;
    color: black;
    font-size: large;
}

.pages {
  color: #316685;
  text-align: center;
  font-size: 3.5rem;
  margin-top: 10%;
}



.background {
  background: #00838C;
  background-blend-mode: darken;
  background-size: cover;
  margin-top: 40px;
}



.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}



.service-list li a {
  color: black;
  text-decoration: none;

}



.firstsection {
  background-color: #DDEAF8;
  
  padding: 20px;
}

.text-big {
  font-family: 'Piazzolla', serif;
  font-weight: bold;
  font-size: 35px;
  text-align: center;
}

.text-small {
  font-size: 18px;
  text-align: center;
  line-height: 1.3em;
}

.btn {
  padding: 8px 20px;
  margin: 7px 0;
  border: 2px solid white;
  border-radius: 8px;
  background: none;
  color: white;
  cursor: pointer;
}

.section {
  display: block;
  align-items: inherit;
  justify-content: center;
  max-width: 100%;
  margin: auto;
}

.paras {
  padding: 0px 65px;
  background-color: rgb(241 245 249);
  border-radius: 20px;
}

.center {
  text-align: center;
}

.secondsection {
  background-color: #EBEBEB;

  padding: 20px;
  display: flex;
}

/*.box-main {
  /* display: block;
  color: black;
  margin-top: 40px;
} */

.box {
  /* margin-top:30px; */
  /* background-color: whitesmoke; */
}

.sectionTag {
  text-align: center;
  padding: 10px 0;
}


    .cubesectionTag {
    background-color: black;
        color: white;
        padding-top: 40px;
        font-family: 'Piazzolla', serif;
        font-weight: bold;
        font-size: 35px;
        text-align: center;
        display:none;
    }

.section-Left {
  flex-direction: row-reverse;
}

.text-footer {
  text-align: center;
  padding: 30px 0;
  font-family: 'Ubuntu', sans-serif;
  display: block;
  justify-content: center;
  color: white;
}

.service td {
  padding: 30px 80px;
  font-size: 1.3em;
}

.rcost td {
  border-top: 1px solid #ddd;
  padding:8px;
  vertical-align: top;
  line-height: 1.5;
  font-size: 1.3em;
}

.doc td {
        line-height: 1.5;
        padding:5px 30px;
        text-align: center;
}

.docrec {
  /*background-color: #ffd900;*/
  background-image: url(bg1.jpg);
        background-repeat: no-repeat;
        background-size: cover;
      padding-top: 30px;
    }

    form {
      background-image: url(card.jpeg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      background-attachment: fixed;
      padding-top: 30px;
      color: white;
      height:700px;
    }

.serviceimg {
        height:200px;
        width:100%;
        border-radius: .5rem;
}

.grad1 {
        height: 200px;
        background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(236, 86, 47, 0.8));
    }

    .grid2 {
      width: 49%;
      border-radius: 50px;
      margin-top: 2%;
    }

    .col-2 {
      margin-left: 2%;
    }

    .fw-600 {
      font-weight: 600;
  }

  .mb-lg-3 {
    margin-bottom: 1rem!important;
}

input, select {
  border-radius: 5px;
}

.margin050 {
margin: 15px 50px;
}

.messageheight {
  height: 100px;
}

.submitbtn {
  text-align: center;
}

/*desk top view */
@media only screen and (min-width: 1141px) {

.main-box {
  
  margin: 0 100px;
}


.secondarysection {
  width: 65%;
    margin-left: 20px;
}

.quoteFlex {
  display:flex;
  padding-top: 30px;
  justify-content: center;
}

.width60 {
  width: 100%;
}
.width25 {
  width:25%;
  
}

.App {
  width: 90%;
  margin: auto;
}

.box-main {
  display: flex;
  height: 500px;
  color: black;
  margin-top: 40px;
}

.crs-details-heading {
  font-size: 35px;
  line-height: 45px;
}

.firstsection {
  width: 40%;
  margin-right: 20px;
}

.section {
  display: flex;
  height: 400px;
}


.topnav a.rightnav {

  float:right;
}

.logo img {
  width: 180px;

}

.paras {
    width: 30%;
    margin: 10px;
}



.secondsection {
  width: 100%;
}

.xxc {
  background-image: url("");
        background-position: center;
}

.rcost, .doc {
  width:70%;
  margin-left: 16%;
}

.contacttable {
border-collapse: separate;
border-spacing: 0 20px;
margin: auto;
}

      .contacttable input, .contacttable textarea, .contacttable span {
      margin-left: 10px;
      width: 140%;
    }

.mobileview {
 display: none;
}

.desktopview {
display: block;
margin: auto;
}

}


/* Mobile view */

@media screen and (max-width: 1140px) {
.topnav a:not(:first-child) {display: none;}
.topnav a.icon {
float: right;
display: block;
}

body {
  position: sticky;
}

.quoteFlex {
  width: 100%;
  margin: auto;
}

.crs-details-heading {
  font-size: 22px;
  line-height: 35px;
}


.paras {
  margin-bottom: 20px;
  padding-bottom: 30px;
}

.secondsection {
  margin: 10px 0;
  height: 230px;
}

input, textarea {
        width:100%;
}

  .contacttable {
        border-collapse: separate;
        border-spacing: 0 20px;
        margin: auto;
    }

.mobileview {
 display: block;
}

.desktopview {
display: none;
}

.docrec {
/*width: min-content;*/
}



}

/* The "responsive" class is added to the topnav with JavaScript when the user clicks on the icon. This class makes the topnav look good on small screens (display the links vertically instead of horizontally) */
@media screen and (max-width: 1140px) {
.topnav.responsive {position: relative;}
.topnav.responsive a.icon {
position: absolute;
right: 0;
top: 0;
}
.topnav.responsive a {
float: none;
display: block;
text-align: left;
}
}


.error {
display: none;
}
.error_show{
color: red;
display: block;
}

input.invalid, textarea.invalid{
border: 2px solid red;
}

input.valid, textarea.valid{
border: 2px solid green;
margin-left: 10px;
}

#contact_submit {
text-align: center;
padding: 10px;
}

input {
height:30px;
}

textarea {
height:80px;
}

label {
  font-family: cursive;
font-size: 20px;
}

.mySlidesC {
display:none;
width:100%;
}

.inlineText {
font-size: medium;
font-family: cursive;
}

.events {
border-top: 1px solid black;
border-bottom: 1px solid black;

}
.event {
font-size: 20px;
padding-left: 50px;
}
.mevents {
padding: 30px;
}


.review {
font-family: "Open Sans", Helvetica;
font-size: 14px;
line-height: 28px;
text-align: left;
color: rgb(51, 51, 51);
width: 100%;
text-align: center;
}

.paras img:hover {
transform: scale(1.4); 
}

