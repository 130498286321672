div#container_cube
{
   position: sticky;
   margin-top: 20px;
   text-align: left;
   padding: 120px 50px 170px 0;
   background-color: black;
   border-radius: 50px;
}
a
{
   color: white;
   text-decoration: none;
}
a:visited
{
   color: white;
}
a:active
{
   color: #FF0000;
}
a:hover
{
   color: white;
}
input:focus, textarea:focus, select:focus
{
   outline: none;
}
#wb_Extension1 
{
   text-align: center;
       margin: auto;
}
#Extension1 
{
   display: inline-block;
   height: 425px;
   margin-top: 50px;
   perspective: 1800px;
   width: 425px;
}
#Extension1 .cube 
{
   animation: rotateCube 16s 1s infinite both;
   height: inherit;
   position: sticky;
   width: inherit;
   transform: rotateX(0deg) rotateY(0deg);
   transform-style: preserve-3d;
}
#Extension1 .cube:hover
{
   animation-play-state: paused !important;
}
#Extension1 .cube-face 
{
   background-size: cover;
   border: 1px solid #888888;
   box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
   height: inherit;
   opacity: 1;
   position: absolute;
   width: inherit;
}
#Extension1 .cube-face-front 
{
   background-image: url(images/kathi-nation-stage-decoration-hazaribagh-jharkhand.jpg);
   transform: translate3d(0, 0, calc(425px / 2));
}
#Extension1 .cube-face-left 
{
   background-image: url(images/party-hall-mehfil-banquet-hazaribagh.jpg);
   transform: rotateY(-90deg) translate3d(0, 0, calc(425px / 2));
}
#Extension1 .cube-face-right 
{
   background-image: url(images/bali-complex-hazaribagh-jharkhand.jpg);
   transform: rotateY(90deg) translate3d(0, 0, calc(425px / 2));
}
#Extension1 .cube-face-top 
{
   background-image: url(images/swan-decorated-mehfil-banquet.jpg);
   transform: rotateX(90deg) translate3d(0, 0, calc(425px / 2));
}
#Extension1 .cube-face-bottom 
{
   background-image: url(images/kathi-nation-ring-ceremony-decoration.jpg);
   transform: rotateX(-90deg) translate3d(0, 0, calc(425px / 2));
}
#Extension1 .cube-face-back 
{
   background-image: url(images/luxurious-hall-mehfil-banquet-hazaribagh.jpg);
   transform: rotateY(180deg) translate3d(0, 0, calc(425px / 2));
}
@keyframes rotateCube
{
   0% 
   {
      transform: rotateX(0deg) rotateY(0deg);
   }
   16% 
   {
      transform: rotateX(90deg) rotateY(0deg);
   }
   33% 
   {
      transform: rotateX(0deg) rotateY(90deg);
   }
   50% 
   {
      transform: rotateX(0deg) rotateY(-90deg); 
   }
   66% 
   {
      transform: rotateX(-90deg) rotateY(0deg); 
   }
   83% 
   {
      transform: rotateX(0deg) rotateY(180deg);
   }
   100% 
   {
      transform: rotateX(0deg) rotateY(0deg);
   }
}
#wb_Heading1
{
   background-color: transparent;
   background-image: none;
   border: 0px solid #000000;
   margin: 0;
   padding: 0;
   text-align: center;
}
#Heading1
{
   color: #000000;
   font-family: Arial;
   font-weight: bold;
   font-style: normal;
   font-size: 32px;
   margin: 0;
   text-align: center;
}
#wb_Heading2
{
   background-color: transparent;
   background-image: none;
   border: 0px solid #000000;
   margin: 0;
   padding: 0;
   text-align: center;
}
#Heading2
{
   color: #000000;
   font-family: Arial;
   font-weight: normal;
   font-style: normal;
   font-size: 16px;
   margin: 0;
   text-align: center;
}
#Heading2 a
{
   color: #000000;
   text-decoration: none;
}
#wb_Heading2
{
   position: sticky;
   left: 294px;
   top: 791px;
   width: 383px;
   height: 24px;
   z-index: 2;
}
#wb_Extension1
{
   position: sticky;
   left: 273px;
   top: 217px;
   width: 425px;
   height: 425px;
   z-index: 0;
}
#wb_Heading1
{
   position: sticky;
   left: 259px;
   top: 69px;
   width: 453px;
   height: 64px;
   z-index: 1;
}
@media only screen and (min-width: 768px) and (max-width: 969px)
{
div#container
{
   width: 768px;
}
body
{
   background-color: #FFFFFF;
   background-image: none;
}
#wb_Extension1
{
   left: 222px;
   top: 190px;
   width: 325px;
   height: 325px;
   visibility: visible;
   display: inline;
}
#Extension1 
{
   height: 325px;
   width: 325px;
}
#Extension1 .cube-face-front 
{
   transform: translate3d(0, 0, calc(325px / 2));
}
#Extension1 .cube-face-left 
{
   transform: rotateY(-90deg) translate3d(0, 0, calc(325px / 2));
}
#Extension1 .cube-face-right 
{
   transform: rotateY(90deg) translate3d(0, 0, calc(325px / 2));
}
#Extension1 .cube-face-top 
{
   transform: rotateX(90deg) translate3d(0, 0, calc(325px / 2));
}
#Extension1 .cube-face-bottom 
{
   transform: rotateX(-90deg) translate3d(0, 0, calc(325px / 2));
}
#Extension1 .cube-face-back 
{
   transform: rotateY(180deg) translate3d(0, 0, calc(325px / 2));
}
#wb_Heading1
{
   left: 158px;
   top: 69px;
   width: 453px;
   height: 64px;
   visibility: visible;
   display: inline;
   margin: 0;
   padding: 0;
}
#wb_Heading2
{
   left: 193px;
   top: 691px;
   width: 383px;
   height: 24px;
   visibility: visible;
   display: inline;
   margin: 0;
   padding: 0;
}
}
@media only screen and (max-width: 767px)
{
div#container
{
   width: fit-content;
   height: fit-content;
}
body
{
   background-color: #FFFFFF;
   background-image: none;
}
#wb_Extension1
{
   left: 115px;
   top: 186px;
   width: 250px;
   height: 250px;
   visibility: visible;
   display: inline;
}
#Extension1 
{
   height: 250px;
   width: 250px;
}
#Extension1 .cube-face-front 
{
   transform: translate3d(0, 0, calc(250px / 2));
}
#Extension1 .cube-face-left 
{
   transform: rotateY(-90deg) translate3d(0, 0, calc(250px / 2));
}
#Extension1 .cube-face-right 
{
   transform: rotateY(90deg) translate3d(0, 0, calc(250px / 2));
}
#Extension1 .cube-face-top 
{
   transform: rotateX(90deg) translate3d(0, 0, calc(250px / 2));
}
#Extension1 .cube-face-bottom 
{
   transform: rotateX(-90deg) translate3d(0, 0, calc(250px / 2));
}
#Extension1 .cube-face-back 
{
   transform: rotateY(180deg) translate3d(0, 0, calc(250px / 2));
}
#wb_Heading1
{
   left: 14px;
   top: 69px;
   width: 453px;
   height: 64px;
   visibility: visible;
   display: inline;
   margin: 0;
   padding: 0;
}
#wb_Heading2
{
   left: 49px;
   top: 634px;
   width: 383px;
   height: 24px;
   visibility: visible;
   display: inline;
   margin: 0;
   padding: 0;
}
}
